import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../context/siteMetadataContext';

export const query = graphql`
  fragment MetaTags on MarkdownRemarkFrontmatter {
    meta {
      metaTitle
      metaDescription
      canonical
      alternatives {
        lang
        href
      }
      noindex
      metaImage {
        publicURL
        childImageSharp {
          fixed(width: 900, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`;

const hashCode = (s) => s.split('')
  .reduce((a, b) => {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);

function googleStructuredData({ faqs }) {
  if (faqs) {
    const data = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqs.map((faq) => ({
        '@type': 'Question',
        name: faq.question,
        acceptedAnswer: {
          '@type': 'Answer',
          text: faq.answer,
        },
      })),
    };
    return JSON.stringify(data, null, 4);
  }

  return '';
}

export default ({
  title,
  metaTitle,
  metaImage,
  metaDescription,
  alternatives,
  canonical,
  slug,
  faqs,
  noindex,
}) => {
  const { siteUrl, lang } = useSiteMetadata();
  const image = metaImage?.childImageSharp?.fixed?.src;
  const ogImage = `${siteUrl}${image}`;
  const headTitle = metaTitle || title;
  const langPart = lang === 'nl' ? '' : `/${lang}`;
  const defaultCanonical = `${siteUrl}${langPart}${slug}`;

  return (
    <Helmet>
      {headTitle && (
        <title>{headTitle}</title>
      )}
      {/* https://github.com/netlify/prerender: */}
      <meta name="fragment" content="!" />
      {faqs && <script type="application/ld+json" key={hashCode(faqs?.toString())}>{googleStructuredData({ faqs })}</script>}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaDescription && <meta name="twitter:description" content={metaDescription} />}
      {metaTitle && <meta name="twitter:title" content={metaTitle} />}
      {image && <meta name="twitter:image" content={image} />}
      {metaTitle && <meta property="og:title" content={metaTitle} />}
      {image && <meta property="og:image" content={ogImage} />}
      {image && <meta property="og:image:secure_url" content={ogImage} />}
      {alternatives && alternatives.map(({ lang, href }) => (
        <link rel="alternate" hrefLang={lang} href={href} />
      ))}
      <link rel="canonical" href={canonical ?? defaultCanonical} />
      {noindex && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};
