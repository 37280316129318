import React from 'react';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown/with-html';

const Content = ({
  className,
  content,
  children,
  markdown = false,
}) => {
  if (children) {
    return <div className={className}>{children}</div>;
  }
  if (markdown) {
    return (
      <ReactMarkdown
        className={className}
        source={content}
        escapeHtml={false}
        renderers={{
          table: ({ children: tableContent }) => <div className="table-wrapper"><table>{tableContent}</table></div>,
        }}
      />
    );
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

const lineHeight = '1.5';
const largerLineHeight = '2';
const compactLineHeight = '1.4';
const largerCompactLineHeight = '1.4';

export default styled(Content)`
  p, ul, ol {
    line-height: ${lineHeight};
    ${(props) => props.compact && css`
      line-height: ${compactLineHeight};
    `}
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
      line-height: ${largerLineHeight};
      ${(props) => props.compact && css`
        line-height: ${largerCompactLineHeight};
      `}
    }
    margin: 0 0 1rem 0;
  }

  h1, h2, h3, h4, h5 {
    font-family:${(props) => props.theme.fontFamilyLight};
  }

  h1 {
    font-size: 2.2rem;
    line-height: 1;
    margin-bottom: .5rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
      font-size: 3.5rem;
    }
  }
  h2 {
    em {
      color: ${(props) => props.theme.primaryColor};
      font-style: normal;
    }
    margin-top: 2rem;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.75rem;
    margin-bottom: .5rem;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1.25rem;
  }

  strong {
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }

  a {
    color: ${(props) => props.theme.secondaryColor};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.secondaryDarkColor};
    }
  }

  > :first-child {
    
  }

  > :last-child {
    margin-bottom: 0;
  }

  blockquote  {
    padding: 1rem;
    margin-left: 0;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -${(props) => props.theme.grid.containerGap};
      width: .3rem;
      background-color: ${(props) => props.theme.secondaryColor};
      @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
        left: -.3rem;
      }
    }
    p {
      margin: 0;
      font-style: italic;
    }
  }
  .table-wrapper {
    overflow-x: auto;
    table {
      border-spacing: 0;
      overflow: scroll;
      min-width: 30rem;
      thead {
        background-color: ${(props) => props.theme.gray1};
        th {
          padding: .5rem;
        }
      }
      tbody {
        border: 0.5px solid ${(props) => props.theme.gray1};
      }
      td {
        border-left: 1px solid ${(props) => props.theme.gray1};
        border-bottom: 1px solid ${(props) => props.theme.gray1};
        padding: .5rem;
      }
      tr {
        td:last-child {
          border-right: 1px solid ${(props) => props.theme.gray1};
        }
      }
    }
  }
`;
