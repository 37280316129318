import React from 'react';
import styled, { css } from 'styled-components';

const Blob = ({ className, block }) => {
  if (block === 'text' || block === 'text-cases') {
    return (
      <svg
        className={className}
        viewBox="0 0 540 485"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1018.000000, -1690.000000)" fill="#FFF1E5">
            <path
              d="M1046.36189,1798.75183 C1030.9712,1854.11064 1010.35613,1906.42822 1020.87186,1959.56434 C1023.84355,1974.58869 1028.46889,1989.56762 1037.91174,2002.07289 C1077.00288,2053.83523 1176.7082,2042.11552 1222.70911,2089.18667 C1239.69389,2106.56658 1247.13748,2130.10491 1264.22733,2147.40287 C1296.14472,2179.70407 1356.5469,2182.26656 1402.92024,2162.28552 C1449.29461,2142.30356 1483.24971,2104.4072 1507.94789,2064.67068 C1517.8492,2048.74058 1526.60093,2032.23012 1532.45961,2015.02732 C1538.98269,1995.87448 1541.83728,1976.1331 1544.66643,1956.50028 C1550.37233,1916.89257 1556.08564,1877.22072 1557.73444,1837.55216 C1558.42313,1821.01158 1558.28474,1803.95498 1550.846,1789.18141 C1540.04434,1767.73432 1515.33009,1754.37624 1489.56072,1747.39833 C1463.79031,1740.4222 1436.23023,1738.70227 1409.6895,1733.84212 C1369.01338,1726.39595 1331.11523,1711.65216 1291.4878,1701.28326 C1263.92845,1694.07361 1234.65319,1689.02218 1205.55344,1690.16022 C1192.80969,1690.6586 1180.09914,1692.34423 1167.58187,1695.55542 C1114.24831,1709.23714 1060.48218,1750.03348 1046.36189,1798.75183 Z"
              transform="translate(1288.000000, 1932.500000) scale(-1, 1) translate(-1288.000000, -1932.500000) "
            />
          </g>
        </g>
      </svg>
    );
  }

  if (block === 'case') {
    return (
      <svg
        className={className}
        viewBox="0 0 540 485"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1018.000000, -1690.000000)" fill="#FFF1E5">
            <path
              d="M1046.36189,1798.75183 C1030.9712,1854.11064 1010.35613,1906.42822 1020.87186,1959.56434 C1023.84355,1974.58869 1028.46889,1989.56762 1037.91174,2002.07289 C1077.00288,2053.83523 1176.7082,2042.11552 1222.70911,2089.18667 C1239.69389,2106.56658 1247.13748,2130.10491 1264.22733,2147.40287 C1296.14472,2179.70407 1356.5469,2182.26656 1402.92024,2162.28552 C1449.29461,2142.30356 1483.24971,2104.4072 1507.94789,2064.67068 C1517.8492,2048.74058 1526.60093,2032.23012 1532.45961,2015.02732 C1538.98269,1995.87448 1541.83728,1976.1331 1544.66643,1956.50028 C1550.37233,1916.89257 1556.08564,1877.22072 1557.73444,1837.55216 C1558.42313,1821.01158 1558.28474,1803.95498 1550.846,1789.18141 C1540.04434,1767.73432 1515.33009,1754.37624 1489.56072,1747.39833 C1463.79031,1740.4222 1436.23023,1738.70227 1409.6895,1733.84212 C1369.01338,1726.39595 1331.11523,1711.65216 1291.4878,1701.28326 C1263.92845,1694.07361 1234.65319,1689.02218 1205.55344,1690.16022 C1192.80969,1690.6586 1180.09914,1692.34423 1167.58187,1695.55542 C1114.24831,1709.23714 1060.48218,1750.03348 1046.36189,1798.75183 Z"
              transform="translate(1288.000000, 1932.500000) scale(-1, 1) translate(-1288.000000, -1932.500000) "
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      viewBox="0 0 779 440"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-194.000000, -2422.000000)">
          <path
            d="M234.914656,2520.66146 C212.712161,2570.88388 182.973013,2618.34725 198.142923,2666.55321 C202.429862,2680.18356 209.102339,2693.77269 222.724526,2705.11767 C279.117118,2752.07732 422.95128,2741.44501 489.311852,2784.14873 C513.813957,2799.91607 524.552031,2821.27044 549.205728,2836.96343 C595.249512,2866.26761 682.385243,2868.59234 749.283086,2850.46521 C816.182405,2832.33725 865.165791,2797.95704 900.7952,2761.90742 C915.078752,2747.45537 927.70393,2732.47681 936.155622,2716.87015 C945.565773,2699.49438 949.683783,2681.58466 953.765095,2663.77345 C961.996378,2627.84068 970.238364,2591.84973 972.616911,2555.86176 C973.6104,2540.85586 973.410758,2525.38184 962.679691,2511.97901 C947.097299,2492.52185 911.444707,2480.40319 874.270008,2474.07271 C837.093806,2467.74385 797.335832,2466.1835 759.048375,2461.7743 C700.369298,2455.019 645.697714,2441.64319 588.531478,2432.23636 C548.774559,2425.69565 506.542293,2421.11291 464.563208,2422.14535 C446.179169,2422.59749 427.843025,2424.12672 409.785693,2427.03997 C332.847102,2439.45225 255.284476,2476.46337 234.914656,2520.66146 Z"
            transform="translate(583.500000, 2642.000000) scale(-1, -1) translate(-583.500000, -2642.000000) "
          />
        </g>
      </g>
    </svg>
  );
};

export default styled(Blob)`
  enable-background:new 0 0 540 485;
  height: 100%;
  transform: ${(props) => (props.mirror ? 'scale(-1,1)' : 'none')};
  ${(props) => (props.block === 'icon-blob-text') && css`
    /* based on icon-blob-text custom width */
    max-width: calc(40rem - 6rem);
  `}
  g {
    fill: ${(props) => props.theme[props.color] || 'pink'};
  }
`;
