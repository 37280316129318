import React from 'react';
import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

// is this the correct place for this?
const BackgroundStyles = styled.div`
  ${(props) => props.notSharp && css`
    background-image: url(${props.image.publicURL});
  `}
  background-position: ${(props) => (`${props.imagePosition} center` || 'center center')};
  background-repeat: no-repeat;
  background-size: ${(props) => ((props.imagePosition !== 'center') ? 'contain' : 'cover')};
  background-position: ${(props) => props.imagePosition || 'center'};
`;
const StyledBackground = ({
  image,
  children,
  imagePosition,
  notSharp,
  className,
}) => {
  if (notSharp) {
    return (
      <BackgroundStyles
        className={className}
        imagePosition={imagePosition}
        notSharp
        image={image}
      >
        {children}
      </BackgroundStyles>
    );
  }
  return (
    <BackgroundStyles
      as={BackgroundImage}
      className={className}
      imagePosition={imagePosition}
      fluid={image.childImageSharp.fluid}
    >
      {children}
    </BackgroundStyles>
  );
};

const PreviewCompatibleImage = ({
  image,
  alt,
  className,
  asBackgroundImage,
  imagePosition,
  children,
  id,
}) => {
  if (image?.childImageSharp?.fluid) {
    if (asBackgroundImage) {
      return (
        <StyledBackground
          imagePosition={imagePosition}
          className={className}
          image={image}
        >
          {children}
        </StyledBackground>
      );
    }
    const maxWidth = image.childImageSharp.fluid.presentationWidth
      ? `max-width: ${image.childImageSharp.fluid.presentationWidth}px; width: 100%;`
      : '';

    return (
      <Img
        className={className}
        css={maxWidth}
        fluid={image.childImageSharp.fluid}
        alt={alt}
      />
    );
  }
  // support SVG's, those will return null in childImageSharp
  // make sure you are requesting `publicURL` in your graphql query
  if (image?.publicURL) {
    if (asBackgroundImage) {
      return (
        <StyledBackground
          imagePosition={imagePosition}
          className={className}
          image={image}
          notSharp
        >
          {children}
        </StyledBackground>
      );
    }
    return <img className={className} src={image.publicURL} alt={alt} />;
  }

  if (typeof image === 'string') {
    if (asBackgroundImage) {
      return (
        <StyledBackground
          imagePosition={imagePosition}
          className={className}
          image={image}
          notSharp
        >
          {children}
        </StyledBackground>
      );
    }
    return <img className={className} src={image} alt={alt} id={id} />;
  }

  return null;
};

export default styled(PreviewCompatibleImage)`
  max-width: 100%;
`;
