import React from 'react';
import styled, { css } from 'styled-components';
import Content from './content';
import Button from '../button';
import ButtonWrapper from '../button/wrapper';
import Blob from '../blobs';

const TemplatedContent = ({
  textTemplate,
  block,
  button,
  buttons,
  template,
  textBackground,
  ...props
}) => (
  <ContentWrapper
    template={template}
    textBackground={textBackground}
    block={block}
  >
    {textBackground?.includes('blob') && (
      <BlobWrapper template={template}>
        <Blob
          mirror={template === 'small-center' || template === 'small-right'}
          block={block}
          color={
            (textBackground?.includes('PastelOrange') && 'pastelOrange')
            || (textBackground?.includes('LightGray') && 'gray1')
            || (textBackground?.includes('PastelPink') && 'pastelPink')
          }
        />
      </BlobWrapper>
    )}
    <Content {...props} />
    <ButtonWrapper>
      {button?.text && (
        <Button css="margin-top: 1rem" block={block} button={button} />
      )}
      {buttons?.map((btn, i) => (
        <Button block="text" button={btn} key={i} />
      ))}
    </ButtonWrapper>
  </ContentWrapper>
);


const BlobWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding-left: 5rem;
  padding-right: 5rem;
  overflow: hidden;
  margin-right: -3rem;
  z-index: -1;
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    overflow: unset;
    margin-right: 0;
  }
  
  ${((props) => props.template === 'small-right'
    || props.template === 'small-left'
    || props.template === 'default'
    || props.template === 'full-textcenter'
    || props.template === 'small-center') && css`
    text-align: left;
  `}
  ${Blob} {
    height: 100%;
  }
`;

const ContentWrapper = styled.div`

  ${(props) => (props.textBackground?.includes('square') && css`

    ${props.textBackground?.includes('PastelOrange') && css`
      background: linear-gradient(${props.theme.pastelOrange}, ${props.theme.pastelOrange});
    `}

    ${props.textBackground?.includes('PastelPink') && css`
      background: linear-gradient(${props.theme.pastelPink}, ${props.theme.pastelPink});
    `}

    ${props.textBackground?.includes('LightGray') && css`
      background: linear-gradient(${props.theme.gray1}, ${props.theme.gray1});
    `}

    background-repeat: no-repeat;

    ${(props.template === 'text-image-left' && css`
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        margin-left: -5rem;
        padding: 3rem 0 3rem 2rem;
      }
    `)}

    ${(props.block === 'text-image' && css`
      position: relative;
      z-index: ${props.theme.zIndex.textBackground};
      margin-top: -5rem;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        background-size: 90% auto;
        margin-top: 0;
      }
      ${(props.template === 'text-image-right' && css`
        background-position: right;
        @media all and (min-width: ${props.theme.breakingpoints.lg}) {
          padding: 3rem 2rem 3rem 0;
          justify-self: end;
          margin-right: -5rem;
        }
      `)}

    `)}

    ${(props.block === 'partner' && css`
      margin-top: -5rem;
      @media all and (min-width: ${props.theme.breakingpoints.lg}) {
        background-size: 90% auto;
        margin-top: 0;
      }
      ${(props.template === 'visuals-content' && css`
        @media all and (min-width: ${props.theme.breakingpoints.lg}) {
          margin-left: -5rem;
          padding: 3rem 0 3rem 5rem;
        }
      `)}
      ${(props.template === 'content-visuals' && css`
        background-position: right;
        @media all and (min-width: ${props.theme.breakingpoints.lg}) {
          margin-right: -5rem;
          padding: 3rem 5rem 3rem 0;
        }
      `)}
    `)}
    padding: 2rem 0;
    ${(props.block === 'text' && css`
        ${(props.template === 'small-right'
          || props.template === 'small-left'
          || props.template === 'default'
          || props.template === 'full-textcenter') && css`
          background-position: left 5rem center;
        `}
        ${(props.template === 'small-center') && css`
          background-position: right 5rem center;
        `}
        @media all and (min-width: ${props.theme.breakingpoints.lg}) {
          background-size: 50% auto;
        }
    `)}
  `)}
  ${(props) => (props.textBackground?.includes('blob') && css`
    position: relative;
    padding-top: 2rem;
    padding-bottom: 2rem;
    ${Content},
    ${Button} {
      position: relative;
      z-index: ${props.theme.zIndex.overBlob};
    }
  `)}
`;

export default styled(TemplatedContent)``;
