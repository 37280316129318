import styled from 'styled-components';
import Button from './button';

const ButtonWrapper = styled.div`
  ${Button} {
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export default ButtonWrapper;